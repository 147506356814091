<template>
  <div>
    <div class="container page-memberInfo-add">
      <el-form :model="form" label-width="100px" :rules="rules" ref="form">
        <el-row :gutter="20" v-for="(item, index) in form" :key="index">
          <el-col :span="12">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="status == 1 ? '报告名称' : '治疗日志'"
                  :prop="index + '.title'"
                  :rules="rules.title"
                >
                  <el-input
                    v-if="status == 1"
                    class="reportInp"
                    v-model="item.title"
                    placeholder="请输入报告名称"
                  >
                  </el-input>
                  <el-input
                    v-else
                    type="textarea"
                    v-model="item.title"
                    :autosize="{ minRows: 5, maxRows: 8 }"
                    placeholder="请输入治疗日志"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="status == 1 ? '检查日期' : '治疗日期'"
                  :prop="index + '.check_time'"
                  :rules="rules.check_time"
                >
                  <el-date-picker
                    v-model="item.check_time"
                    type="date"
                    @blur="checkTimeBind(index)"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-form-item>
							<el-button v-if="status==1" type="" @click="addBtn">添加报告</el-button>
						</el-form-item> -->
          </el-col>
          <el-col :span="12">
            <el-form-item :label="status == 1 ? '报告文件' : '治疗文件'">
              <el-upload
                class="upload_btn upload_btnbox"
                list-type="picture"
                :data="{ name: index }"
                :file-list="item.showImages"
                :on-progress="handleProgress"
                :action="httpUrl + 'common/upload'"
                :on-preview="handlePreview"
                :on-success="handleAvatarSuccess"
                multiple
                :limit="20"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
                :on-remove="handleRemove"
              >
                <el-button size="small" type="">点击上传</el-button>
                <template #file="{ file }">
                  <div
                    class="file-preview"
                    @click="handlePreview(file)"
                    style="cursor: pointer"
                  >
                    <div class="file-pic" v-if="file.type == 'image'">
                      <img :src="file.url" style="height: 70px; width: 70px" />
                    </div>
                    <div
                      class="file-pic file-type"
                      v-else-if="file.type == 'file'"
                    >
                      <el-image
                        :src="require('../../assets/img/pdf.png')"
                        fit="cover"
                      ></el-image>
                    </div>
                    <div
                      class="file-pic file-type"
                      v-else-if="file.type == 'video'"
                      style="width: unset; height: unset; margin: 0 auto"
                    >
                      <video
                        style="width: 200px; height: 200px"
                        controls="controls"
                        :src="file.url"
                      ></video>
                    </div>
                    <div class="file-name" v-if="file.type !== 'video'">
                      {{ file.name }}
                    </div>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click.stop="handleRemove(file)"
                      style="display: unset; top: 0; right: 0"
                    >
                      <i class="el-icon-close"></i>
                    </span>
                  </div>
                </template>
              </el-upload>
              <el-dialog v-model="dialogVisible" :width="600">
                <img
                  v-if="dialogImageUrl"
                  style="width: 100%"
                  :src="dialogImageUrl"
                  alt=""
                />
                <video
                  v-if="dialogVideoUrl"
                  style="width: 100%"
                  controls="controls"
                  :src="dialogVideoUrl"
                ></video>
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button
              type="danger"
              icon="el-icon-remove"
              v-if="index > 0"
              @click="removeBtn(index)"
              >删除
            </el-button>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button v-if="status == 1" type="" @click="addBtn"
            >添加报告</el-button
          >
        </el-form-item>
        <el-form-item label="创建时间" v-if="status == 1">
          <el-date-picker
            v-model="create_time"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-folder-checked"
            @click="submit('form')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addReport, addLog } from "../../api/index";
import globalData from "../../utils/data";
export default {
  name: "memberAdd",
  data() {
    return {
      httpUrl: globalData.httpUrl,
      reloadPic: true,
      dialogImageUrl: "",
      dialogVideoUrl: "",
      dialogVisible: false,
      uploadloading: false,
      showUploading: false,
      form: [
        {
          title: "",
          check_time: "",
          image: "",
          video: "",
          file: "",
          showImages: [],
        },
      ],
      id: "",
      status: 1, //1:新增报告   2：新增治疗日志
      rules: {
        title: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        check_time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur",
          },
        ],
        // create_time: [
        //   {
        //     required: true,
        //     message: "请选择创建时间",
        //     trigger: "blur",
        //   },
        // ],
      },
      fileList: [],
      create_time: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
  },

  methods: {
    //编辑修改文件
    handleProgress() {
      this.uploadloading = true;
      this.showUploading = true;
    },

    // add
    addBtn() {
      this.form.push({
        title: "",
        check_time: "",
        image: "",
        video: "",
        file: "",
        showImages: [],
      });
    },

    // remove
    removeBtn(index) {
      this.form.splice(index, 1);
    },

    // submit
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (this.create_time == "" && this.status == 1) {
          this.$notify({
            title: "提示",
            message: "请选择创建时间",
            type: "error",
          });
          return false;
        }
        if (valid) {
          if (this.status == 1) {
            this.setData();
          } else {
            this.setLog();
          }
        } else {
          return false;
        }
      });
    },

    // 新增报告
    setData() {
      let data = {
        id: this.id,
        arr: this.form,
        create_time: this.create_time,
      };
      addReport(data).then(() => {
        this.$notify({
          title: "提示",
          message: "添加成功",
          type: "success",
        });
        this.$router.go(-1); //返回上一层
      });
    },

    // 添加日志
    setLog() {
      let temp = this.form[0];
      let image = [temp.image, temp.video, temp.file].reduce((prev, item) =>
          item ? prev + ";" + item : prev
        );  
      let data = {
        id: this.id,
        date: temp.check_time,
        msg: temp.title,
        image,//image.substr(1),
        create_time: this.create_time,
      };
      addLog(data).then(() => {
        this.$notify({
          title: "提示",
          message: "添加成功",
          type: "success",
        });
        this.$router.go(-1); //返回上一层
      });
    },

    checkTimeBind(index) {
      let date = this.form[index].check_time;
      console.log(date);
      if (date) {
        const d =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        this.form[index].check_time = d;
      } else {
        console.log(date);
        // this.form[index].check_time = ''
      }
    },
    handleRemove(fileDeleted) {
      //文件列表移除文件时的钩子
      // console.log(file, fileList);
      console.log(fileDeleted);
      console.log(this.form);
      let idx = fileDeleted.response.data.name;
      let nameOfDeleted = fileDeleted.response.data.img_name;
      this.form[idx].image = "";
      this.form[idx].video = "";
      this.form[idx].file = "";
      let fileList = this.form[idx].showImages;
      console.log(fileList);
      let indexDeleted = null;

      console.log(nameOfDeleted, indexDeleted);

      if (fileList.length > 0) {
        fileList.forEach((item, index) => {
          let suffix = item.response.data.suffix.toUpperCase();
          let img_name = item.response.data.img_name;

          if (img_name === nameOfDeleted) {
            indexDeleted = index;
            return;
          }

          if (
            suffix === "JPG" ||
            suffix === "JPEG" ||
            suffix === "PNG" ||
            suffix === "GIF"
          ) {
            this.form[idx].image += img_name + ";";
          } else if (
            suffix === "AVI" ||
            suffix === "MOV" ||
            suffix === "MP4" ||
            suffix === "3GP" ||
            suffix === "FLV" ||
            suffix === "RMVB" ||
            suffix === "RM"
          ) {
            this.form[idx].video += img_name + ";";
          } else {
            this.form[idx].file += img_name + ";";
          }
        });
        if (this.form[idx].image.length > 0) {
          this.form[idx].image = this.form[idx].image.substr(
            0,
            this.form[idx].image.length - 1
          );
        }
        if (this.form[idx].video.length > 0) {
          this.form[idx].video = this.form[idx].video.substr(
            0,
            this.form[idx].video.length - 1
          );
        }
        if (this.form[idx].file.length > 0) {
          this.form[idx].file = this.form[idx].file.substr(
            0,
            this.form[idx].file.length - 1
          );
        }
      }
      this.form[idx].showImages.splice(indexDeleted, 1);
      console.log(this.form[idx]).showImages;
    },
    handlePreview(file) {
      //点击文件列表中已上传的文件时的钩子
      console.log(file);
      this.dialogImageUrl = "";
      this.dialogVideoUrl = "";
      let suffix = file.response.data.suffix.toUpperCase();
      if (
        suffix === "JPG" ||
        suffix === "JPEG" ||
        suffix === "PNG" ||
        suffix === "GIF"
      ) {
        this.dialogImageUrl = file.response.data.img_name;
        this.dialogVisible = true;
      } else if (
        suffix === "AVI" ||
        suffix === "MOV" ||
        suffix === "MP4" ||
        suffix === "3GP" ||
        suffix === "FLV" ||
        suffix === "RMVB" ||
        suffix === "RM"
      ) {
        this.dialogVideoUrl = file.response.data.img_name;
        this.dialogVisible = true;
      } else {
        window.open(file.response.data.img_name, "_blank");
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeAvatarUpload(file) {
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        this.$message.error("上传文件大小不能超过100MB!");
      }
      return isLt100M;
    },
    async handleAvatarSuccess(response, file, fileList) {
      //上传成功的钩子
      this.uploadloading = false;
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 600);
      });
      if (this.uploadloading == true) return;
      this.showUploading = false;
      let idx = response.data.name;
      this.form[idx].image = "";
      this.form[idx].video = "";
      this.form[idx].file = "";
      // let a1 = this.form[idx].image.split(';');
      //  let a2 = this.form[idx].video.split(';');
      //   let a3 = this.form[idx].file.split(';');
      //   console.log(a1);

      if(this.form[idx].showImages.length > 19) {this.$message.error("最多上传20张"); return false;}
      // if(a2.length > 20) {this.$message.error("最多上传20个视频"); return false;}
      // if(a3.length > 20) {this.$message.error("最多上传20张文件"); return false;}

      fileList.forEach((item, index) => {
        console.log(item, index);
        let suffix = item.response.data.suffix.toUpperCase();
        let img_name = item.response.data.img_name;
        console.log(img_name);
        if (!this.form[idx].showImages[index]) {
          this.form[idx].showImages.push(item);
        }
        if (
          suffix === "JPG" ||
          suffix === "JPEG" ||
          suffix === "PNG" ||
          suffix === "GIF"
        ) {
          this.form[idx].image += img_name + ";";
          this.form[idx].showImages[index].type = "image";
        } else if (
          suffix === "AVI" ||
          suffix === "MOV" ||
          suffix === "MP4" ||
          suffix === "3GP" ||
          suffix === "FLV" ||
          suffix === "RMVB" ||
          suffix === "RM"
        ) {
          this.form[idx].video += img_name + ";";
          this.form[idx].showImages[index].type = "video";
        } else {
          this.form[idx].file += img_name + ";";
          this.form[idx].showImages[index].type = "file";
        }
      });

      if (this.form[idx].image.length > 0) {
        this.form[idx].image = this.form[idx].image.substr(
          0,
          this.form[idx].image.length - 1
        );
      }
      if (this.form[idx].video.length > 0) {
        this.form[idx].video = this.form[idx].video.substr(
          0,
          this.form[idx].video.length - 1
        );
      }
      if (this.form[idx].file.length > 0) {
        this.form[idx].file = this.form[idx].file.substr(
          0,
          this.form[idx].file.length - 1
        );
      }
    },
  },
};
</script>
<style>
.upload_btn .el-upload {
  border: none;
  text-align: left;
  height: auto;
}

.page-memberInfo-add
  .upload_btnbox
  .el-upload-list--picture
  .el-upload-list__item {
  padding: 10px;
}
</style>
<style scoped>
.file-preview {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  position: relative;
}

.file-preview .file-pic {
  height: 70px;
  width: 70px;
}

.file-preview .file-type {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.file-preview .file-name {
  margin-left: 8px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload_btn {
  border: none;
}

.el-upload {
  border: none;
}

.avatar-uploader,
.el-upload--text {
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 146px;
  height: 146px;
  display: block;
}

.reportInp {
  width: 220px;
}
</style>
